var exports = {};

/**
 * Returns a `Boolean` on whether or not the a `String` starts with '0x'
 * @param {String} str the string input value
 * @return {Boolean} a boolean if it is or is not hex prefixed
 * @throws if the str input is not a string
 */
exports = function isHexPrefixed(str) {
  if (typeof str !== "string") {
    throw new Error("[is-hex-prefixed] value must be type 'string', is currently type " + typeof str + ", while checking isHexPrefixed.");
  }

  return str.slice(0, 2) === "0x";
};

export default exports;